import { Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [token, setToken] = React.useState(true);
  const selectedLanguage = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    async function fetchData() {
      await fetch("/jwt/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        signal,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.auth === false) {
            logout();
          }

          setToken(data.auth);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("Aborted");
          } else {
            Sentry.captureException(err);
          }
          logout();
        });
    }

    async function logout() {
      fetch("/cookies/clear-logout", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      })
        .then((res) => res.json())
        .then((data) => {
          window.location.href = `https://redfoxlotto.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }`;
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("Aborted");
          } else {
            Sentry.captureException(err);
          }
        });
    }

    fetchData();

    return () => {
      controller.abort();
    };
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
