import React from "react";
import DatePickerCustom from "components/Table/datePicker";
import TableComponent from "components/Table/index";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Transactions = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t("transactions.title")}| ${t("site-name")}`);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "transaction_id",
        header: t("transactions.fields.transaction-id"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Ceil: ({ cell }) => {
          return cell.row.original.transaction_type === "ticket-purchase" ? (
            <Link to={`/my-ticket/${cell.row.original.transaction_id}`}>
              {cell.row.original.transaction_id.toUpperCase()}
            </Link>
          ) : (
            cell.row.original.transaction_id.toUpperCase()
          );
        },
      },
      {
        accessorKey: "transaction_type",
        header: t("transactions.fields.type"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Ceil: ({ cell }) => cell.getValue().toUpperCase(),
      },
      {
        accessorFn: (row) => new Date(row.transaction_date),
        id: "transaction_date",
        header: t("transactions.fields.date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "balance_before",
        enableColumnFilter: false,
        header: t("transactions.fields.balance-before"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.balance_before?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "amount",
        header: t("transactions.fields.amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "balance_after",
        enableColumnFilter: false,
        header: t("transactions.fields.balance-after"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.balance_after?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "status",
        header: t("transactions.fields.status"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => {
          return (
            <span
              className={`badge-dot mr-4 badge ${
                cell.row.original.status === "success"
                  ? "badge-success"
                  : cell.row.original.status === "pending"
                  ? "badge-info"
                  : "badge-danger"
              }`}
            >
              {cell.row.original.status}
            </span>
          );
        },
      },
    ],
    [t]
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="card shadow">
          <TableComponent url="/api/v1/userTransactions?" columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
