import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const savedLanguage = localStorage.getItem("i18nextLng");
const initialLanguage = savedLanguage || "en";

const DETECTION_OPTIONS = {
  order: ["navigator", "htmlTag", "path", "subdomain"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // debug: true,
    detection: DETECTION_OPTIONS,
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      de: {
        translations: require("./locales/de/translations.json"),
      },
      es: {
        translations: require("./locales/es/translations.json"),
      },
      fr: {
        translations: require("./locales/fr/translations.json"),
      },
      it: {
        translations: require("./locales/it/translations.json"),
      },
      bg: {
        translations: require("./locales/bg/translations.json"),
      },
      da: {
        translations: require("./locales/da/translations.json"),
      },
      fi: {
        translations: require("./locales/fi/translations.json"),
      },
      hu: {
        translations: require("./locales/hu/translations.json"),
      },
      pl: {
        translations: require("./locales/pl/translations.json"),
      },
      ro: {
        translations: require("./locales/ro/translations.json"),
      },
      sv: {
        translations: require("./locales/sv/translations.json"),
      },
      uk: {
        translations: require("./locales/uk/translations.json"),
      },
      cs: {
        translations: require("./locales/cs/translations.json"),
      },
      el: {
        translations: require("./locales/el/translations.json"),
      },
      et: {
        translations: require("./locales/et/translations.json"),
      },
      id: {
        translations: require("./locales/id/translations.json"),
      },
      ja: {
        translations: require("./locales/ja/translations.json"),
      },
      lt: {
        translations: require("./locales/lt/translations.json"),
      },
      lv: {
        translations: require("./locales/lv/translations.json"),
      },
      pt: {
        translations: require("./locales/pt/translations.json"),
      },
      ru: {
        translations: require("./locales/ru/translations.json"),
      },
      sk: {
        translations: require("./locales/sk/translations.json"),
      },
      sl: {
        translations: require("./locales/sl/translations.json"),
      },
      tr: {
        translations: require("./locales/tr/translations.json"),
      },
      zh: {
        translations: require("./locales/zn-CN/translations.json"),
      },
      ar: {
        translations: require("./locales/ar/translations.json"),
      },
      ee: {
        translations: require("./locales/ee/translations.json"),
      },
      sr: {
        translations: require("./locales/sr/translations.json"),
      },
      ko: {
        translations: require("./locales/ko/translations.json"),
      },
      no: {
        translations: require("./locales/no/translations.json"),
      },
      mk: {
        translations: require("./locales/mk/translations.json"),
      },
      fa: {
        translations: require("./locales/fa/translations.json"),
      },
      hr: {
        translations: require("./locales/hr/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    lng: initialLanguage,
  });

export default i18n;
